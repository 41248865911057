import { isNull } from "lodash-es";
import { useState } from "react";
import { useAppContext, useAppDispatch, useLineChartView } from "../../context";
import { Checkbox, LineChart, ToggleGroup } from "../../components";
import { format } from "date-fns";
import { lineChartRoot, chartCheckboxes } from "./LineChart.css";
import { useMediaQuery } from "usehooks-ts";

export const LineChartForWeek = () => {
  const isDesktopView = useMediaQuery("(min-width:768px)");
  const isMobileView = !isDesktopView;
  const { sites, date, periodDates, projects, group, data } = useAppContext();
  const { setQueries } = useAppDispatch();
  const isPeriod =
    (date === "month" || date === "year" || date === "week") &&
    sites === "main";
  const datasets = useLineChartView();
  const [checkbox, setCheckbox] = useState<
    Array<{
      id: number;
      color: string | null;
      name: string;
      active: boolean;
    }>
  >(
    projects.map((item) => ({
      id: item.id,
      color: item.color,
      name: item.name,
      active: true,
    }))
  );

  if (
    sites === "all" ||
    date === "today" ||
    date === "yesterday" ||
    !isNull(group)
  ) {
    return null;
  }

  const filtredDataset = datasets.filter((projectDataset) => {
    const currentDatasetCheck = checkbox.find(
      (check) => check.name === (projectDataset?.label ?? "wtf")
    );
    return currentDatasetCheck?.active;
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
      }}
    >
      <div>
        {isPeriod && (
          <ToggleGroup
            onValueChange={setQueries}
            defaultValue={data}
            togglers={[
              {
                value: "visits",
                label: "Визиты",
              },
              {
                value: "views",
                label: "Просмотры",
              },
              {
                value: "deep",
                label: "Глубина",
              },
              {
                value: "dzenVisits",
                label: "Дзен Новости: визиты",
              },
            ]}
          />
        )}
      </div>
      <div className={[lineChartRoot({ isMobile: isMobileView })].join(" ")}>
        <div
          style={{
            gridArea: "g",
          }}
        >
          <LineChart
            key={sites}
            labels={periodDates.map((date) => {
              const formated = format(new Date(date), "dd.MM.yyyy");
              return formated;
            })}
            //@ts-ignore
            datasets={filtredDataset}
          />
        </div>
        <div
          className={[chartCheckboxes({ isMobile: isMobileView })].join(" ")}
        >
          {checkbox.map((project) => {
            return (
              <Checkbox
                name={project.name}
                label={project.name}
                color={project?.color || undefined}
                onCheckedChange={(value) => {
                  const newState = checkbox.map((check) => {
                    if (check.id === project.id) {
                      return {
                        ...check,
                        active: value,
                      };
                    } else {
                      return check;
                    }
                  });
                  setCheckbox(newState);
                }}
                checked={project.active}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
