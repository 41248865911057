import { useAppContext, useByDayProjectSelector } from "../../context";
import { capitalize } from "lodash-es";
import { format } from "date-fns";
import ruLocale from "date-fns/locale/ru";
import { typographyRecipe } from "../../styles/typhography.css";
import { periodTable, periodTableItems } from "./PeriodTable.css";
import { Table } from "../Table";
import { useMediaQuery } from "usehooks-ts";

export const PeriodTable = () => {
  const isDesktopView = useMediaQuery("(min-width:768px)");
  const isMobileView = !isDesktopView;
  const { date, periodDates, sites } = useAppContext();
  const { columns, data } = useByDayProjectSelector();

  const formatDate = date === "year" ? "yyyy" : "dd.MM.yyyy";
  const formatEEEE = date === "year" ? "MMM" : "EEEE";

  const reversedPeriodDates = periodDates.reduce<Array<string>>(
    (accumulator, value) => {
      return [value, ...accumulator];
    },
    []
  );
  if (
    sites === "main" &&
    (date === "week" || date === "year" || date === "month")
  ) {
    return (
      <div className={periodTable}>
        {reversedPeriodDates.map((date, index) => {
          const formated = format(new Date(date), formatDate, {
            locale: ruLocale,
          });

          const eeee = format(new Date(date), formatEEEE, {
            locale: ruLocale,
          });

          return (
            <div className={`${periodTableItems({ isMobile: isMobileView })}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",

                  ...(index === 0 ? { gap: "14px" } : { gap: "44px" }),
                }}
              >
                {index === 0 && (
                  <span
                    style={{
                      height: "44px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    className={typographyRecipe({
                      color: "mainText",
                      sizes: "big",
                      weight: "black",
                    })}
                  >
                    Дата
                  </span>
                )}
                <span
                  className={typographyRecipe({
                    color: "text",
                    sizes: "big",
                    weight: "medium",
                  })}
                >
                  {formated} {capitalize(eeee)}
                </span>
              </div>

              <Table
                key={date}
                columns={columns}
                data={data[date]}
                showHead={isMobileView ? true : index === 0}
                showLastBorder={false}
                isPeriod={true}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
