import React, { FC } from "react";
import { typographyRecipe } from "../../styles/typhography.css";
import { Root, Indicator } from "@radix-ui/react-checkbox";
import { ReactComponent as Indi } from "./Indi.svg";
import { container, box } from "./Checkbox.css";

interface CheckboxProps {
  label: string;
  name: string;
  color?: string;
  onCheckedChange: (props: any) => void;
  checked?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  color = "#fff",
  onCheckedChange,
  checked,
}) => (
  <div className={container}>
    <Root
      defaultChecked
      id={name}
      className={box}
      style={{
        border: `2px solid ${color}`,
        color: color,
      }}
      onCheckedChange={onCheckedChange}
      checked={checked}
    >
      <Indicator>
        <Indi />
      </Indicator>
    </Root>
    <label
      className={typographyRecipe({
        sizes: "small",
        weight: "medium",
        color: "text",
      })}
      htmlFor={name}
    >
      {label}
    </label>
  </div>
);
