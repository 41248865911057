import React, { FC } from "react";
import {
  Root,
  Item,
  ToggleGroupItemProps,
  ToggleProps,
} from "@radix-ui/react-toggle-group";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import { toggleGroup, toggleButton } from "./toggleGroup.css";
import { typographyRecipe } from "../../styles/typhography.css";
import { isNull } from "lodash-es";
import { useMediaQuery } from "usehooks-ts";

import * as classes from "./select.css";
interface ToogleItemProps extends ToggleGroupItemProps {
  children: string;
  disabled?: boolean;
}
const ToggleItem: FC<ToogleItemProps> = ({ children, ...props }) => {
  return (
    <Item className={toggleButton} {...props}>
      <span className={typographyRecipe({ weight: "medium", sizes: "based" })}>
        {children}
      </span>
    </Item>
  );
};

const SelectItem = React.forwardRef(
  //@ts-ignore
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(classes.selectItem, className)}
        {...props}
        //@ts-ignore
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className={classes.selectItemIndicator}>
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

interface ToggleGroupProps {
  togglers: Array<{
    value: string;
    label: string;
    ariaLabel?: string;
  }>;
  isOnlyToggler?: boolean;
  defaultValue?: string | null;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  value?: string | null;
}

const ToggleGroup: FC<ToggleGroupProps> = ({
  togglers,
  onValueChange,
  defaultValue,
  disabled = false,
  isOnlyToggler = false,
  value,
}) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");
  const isMobileView = !isDesktopView;
  if (isMobileView && !isOnlyToggler) {
    return (
      <Select.Root
        onValueChange={onValueChange}
        defaultValue={isNull(defaultValue) ? undefined : defaultValue}
        disabled={disabled}
        value={isNull(value) ? undefined : value}
      >
        <Select.Trigger className={classes.selectTrigger} aria-label="Food">
          <Select.Value placeholder="Группы" />
          <Select.Icon className={classes.selectIcon}>
            <ChevronDownIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className={classes.selectContent}>
            <Select.ScrollUpButton className={classes.selectScrollButton}>
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport className={classes.selectViewport}>
              <Select.Group>
                {togglers.map(({ value, label, ariaLabel }) => {
                  return (
                    //  @ts-ignore
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  );
                })}
              </Select.Group>
            </Select.Viewport>
            <Select.ScrollDownButton className={classes.selectScrollButton}>
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    );
  }
  return (
    <Root
      className={toggleGroup}
      aria-label="Text alignment"
      type="single"
      onValueChange={onValueChange}
      defaultValue={isNull(defaultValue) ? undefined : defaultValue}
      disabled={disabled}
      value={isNull(value) ? undefined : value}
    >
      {togglers.map(({ value, label, ariaLabel }) => {
        return (
          <ToggleItem
            key={value}
            value={value}
            aria-label={ariaLabel}
            disabled={disabled}
          >
            {label}
          </ToggleItem>
        );
      })}
    </Root>
  );
};

export { ToggleGroup };
