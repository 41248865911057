import { FC, ReactElement, useEffect } from "react";

import { useAppContext, useAppDispatch } from "./hooks/useAppState";

export interface AppContextProviderProps {
  children?: ReactElement | ReactElement[];
}

const AppContextStateMenager: FC<AppContextProviderProps> = ({ children }) => {
  const { getProjects, getGroups } = useAppDispatch();
  const { sites, date, group } = useAppContext();

  useEffect(() => {
    getProjects({});
  }, [sites, date, getProjects, getGroups, group]);

  useEffect(() => {
    getGroups({});
  }, [getGroups]);

  return <>{children}</>;
};

export { AppContextStateMenager };
