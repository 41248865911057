import React, { FC, ReactElement } from "react";
import { chunk, reverse, sortBy } from "lodash-es";
import { typographyRecipe } from "../../styles/typhography.css";
import { DoughnutChart } from "../DoughnutChart";
import {
  card,
  indi,
  databoxItem,
  databoxItemCount,
  databox,
  chart,
} from "./Card.css";
import { BarChart } from "../BarChart";
import { GraphType } from "../../type";
import { niceCount } from "../../utils";
import { useMediaQuery } from "usehooks-ts";

export interface DataboxItemProps {
  className?: string;
  label: string;
  count: number;
  color: string;
  site: string;
}

const DataboxItem: FC<DataboxItemProps> = ({
  label,
  count,
  className = "",
  color,
  site,
}) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");

  return (
    <div className={[className, databoxItem].join(" ")}>
      <div className={indi} style={{ backgroundColor: color }} />
      <a
        href={site}
        target={"_blank"}
        rel="noreferrer"
        style={{
          color: "unset",
          textDecoration: "none",
        }}
      >
        <span
          className={typographyRecipe({
            sizes: isDesktopView ? "big" : "small",
            weight: "medium",
            color: "text",
          })}
        >
          {label}
        </span>
      </a>
      <span
        className={[
          typographyRecipe({
            sizes: isDesktopView ? "big" : "small",
            weight: "black",
          }),
          databoxItemCount,
        ].join(" ")}
      >
        {niceCount(count)}
      </span>
    </div>
  );
};

export interface DataboxProps {
  children?: ReactElement | ReactElement[];
}

const Databox: FC<DataboxProps> = ({ children }) => {
  return <div className={databox}>{children}</div>;
};

export interface CardProps {
  label: string;
  graph: GraphType;
  data?: Array<{
    id: number | string;
    color: string;
    label: string;
    count: number;
    site: string;
  }>;
}

const Card: FC<CardProps> = ({ label, graph, data = [] }) => {
  const sortData = sortBy(data, (o) => {
    return o.count;
  }).reverse();
  return (
    <div className={[card].join(" ")}>
      <div
        className={typographyRecipe({
          sizes: "big",
          weight: "black",
          color: "mainText",
        })}
      >
        {label}
      </div>
      {graph === "donut" && (
        <div className={chart}>
          <DoughnutChart
            data={data.map((item) => ({
              label: item.label,
              color: item.color,
              count: item.count,
            }))}
          />
        </div>
      )}
      {graph === "bar" && (
        <div className={chart}>
          <BarChart
            data={data.map((item) => ({
              label: item.label,
              color: item.color,
              count: item.count,
            }))}
          />
        </div>
      )}
      <Databox>
        {sortData?.map(({ id, ...props }) => {
          return <DataboxItem key={id} {...props} />;
        })}
      </Databox>
    </div>
  );
};

export { Card };
