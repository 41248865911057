import React, { FC, ReactElement } from "react";
import { container } from "./Container.css";

export interface ContainerProps {
  children: ReactElement | ReactElement[];
}

const Container: FC<ContainerProps> = ({ children }) => {
  return <div className={[container].join(" ")}>{children}</div>;
};

export { Container };
