import { DateToggle, Metric, Period, Project } from "./type";
import { stringify } from "query-string";
const BASE_URL = process.env.REACT_APP_API_URL;

const token =
  "WriiKjBt1DmtYEV61k2DStoI9UQlL5QqxLorymhDObphK6FEvXaHlghxuh0vpGzz";

const getMetrics = async (): Promise<Array<Metric>> => {
  return fetch(`${BASE_URL}/metrics/`, {
    headers: { "X-CSRFToken": token },
  }).then((res) => (res.ok ? res.json() : []));
};

const getProjects = async (query?: {
  is_important?: boolean;
  period?: DateToggle;
  group?: string;
}): Promise<{
  period_dates: Array<string>;
  metrics: Array<Metric>;
  projects: Array<Project>;
}> => {
  const queryString = stringify(query ?? {});
  const isQuery = Boolean(queryString);
  return fetch(`${BASE_URL}/projects/${isQuery ? `?${queryString}` : ""}`, {
    headers: { "X-CSRFToken": token },
  }).then((res) => (res.ok ? res.json() : []));
};

const getGroups = async (): Promise<Array<{ id: number; name: string }>> => {
  return fetch(`${BASE_URL}/projects_groups/`, {
    headers: { "X-CSRFToken": token },
  }).then((res) => (res.ok ? res.json() : []));
};

const getProject = async ({ id }: { id: number }): Promise<Project> => {
  return fetch(`${BASE_URL}/projects/${id}/`, {
    headers: { "X-CSRFToken": token },
  }).then((res) => (res.ok ? res.json() : null));
};

export { getProjects, getProject, getMetrics, getGroups };
