// import { useSe } from "react";

import { useSelector, useDispatch } from "react-redux";
import store, { Dispatch, RootState } from "../store";

const useAppContext = () => {
  const context = useSelector((state: RootState) => state.rootState);
  return context;
};

const useAppContextLoading = () => {
  const context = useSelector(
    (state: RootState) => state.loading.effects.rootState
  );

  return {
    isLoadingProjects: context.getProjects,
  };
};

const useAppDispatch = () => {
  const dispatch: Dispatch = useDispatch();

  return {
    setQueries: dispatch.rootState.setQueries,
    getProjects: dispatch.rootState.getProjects,
    getGroups: dispatch.rootState.getGroups,
  };
};
const totalSelector = store.select((models) => ({
  total: models.rootState.total,
}));
const useMetricsTotal = () => {
  const total = useSelector(totalSelector);
  return total;
};

const tableViewSelector = store.select((models) => ({
  table: models.rootState.table,
}));
const tableViewColumnsSelector = store.select((models) => ({
  columns: models.rootState.tableColumns,
}));
const useProjectTableView = () => {
  const {
    table: { data },
  } = useSelector(tableViewSelector);
  const {
    columns: { columns },
  } = useSelector(tableViewColumnsSelector);
  return { columns, data };
};

const byDayProjectSelector = store.select((models) => ({
  table: models.rootState.byDayProject,
}));

const useByDayProjectSelector = () => {
  const { table } = useSelector(byDayProjectSelector);
  const {
    columns: { columns },
  } = useSelector(tableViewColumnsSelector);
  return { data: table, columns };
};

const lineChartViewSelector = store.select((models) => ({
  chart: models.rootState.lineChartView,
}));

const useLineChartView = () => {
  const { chart } = useSelector(lineChartViewSelector);

  return chart;
};

export {
  useAppContext,
  useAppDispatch,
  useAppContextLoading,
  useMetricsTotal,
  useProjectTableView,
  useByDayProjectSelector,
  useLineChartView,
};
