import "packages/vanilla-extract/src/capsize.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"packages/vanilla-extract/src/capsize.css.ts.vanilla.css\",\"source\":\"Ll8xZDBnOXFrNCB7CiAgZm9udC1zaXplOiB2YXIoLS1fMWQwZzlxazApOwogIGxpbmUtaGVpZ2h0OiB2YXIoLS1fMWQwZzlxazEpOwp9Ci5fMWQwZzlxazQ6OmJlZm9yZSB7CiAgY29udGVudDogJyc7CiAgbWFyZ2luLWJvdHRvbTogdmFyKC0tXzFkMGc5cWsyKTsKICBkaXNwbGF5OiB0YWJsZTsKfQouXzFkMGc5cWs0OjphZnRlciB7CiAgY29udGVudDogJyc7CiAgbWFyZ2luLXRvcDogdmFyKC0tXzFkMGc5cWszKTsKICBkaXNwbGF5OiB0YWJsZTsKfQ==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/styles/theme.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.ts.vanilla.css\",\"source\":\"Ll8xeDZpczVmZiB7CiAgLS1fMXg2aXM1ZjA6ICMwMDAwMDA7CiAgLS1fMXg2aXM1ZjE6ICNGRkZGRkY7CiAgLS1fMXg2aXM1ZjI6ICMzQTNCM0Y7CiAgLS1fMXg2aXM1ZjM6ICMzQTNCM0Y7CiAgLS1fMXg2aXM1ZjQ6IDhweDsKICAtLV8xeDZpczVmNTogIzNBM0IzRjsKICAtLV8xeDZpczVmNjogIzlBOUE5QTsKICAtLV8xeDZpczVmNzogI0YyRjJGMjsKICAtLV8xeDZpczVmODogIzFFMUUyMDsKICAtLV8xeDZpczVmOTogIzc4QjZFNzsKICAtLV8xeDZpczVmYTogI0ZFNUEzRTsKICAtLV8xeDZpczVmYjogI0ZGRDk2NDsKICAtLV8xeDZpczVmYzogIzk3Q0M2NDsKICAtLV8xeDZpczVmZDogSW50ZXI7CiAgLS1fMXg2aXM1ZmU6IFJvYm90bzsKfQ==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/styles/typhography.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/typhography.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUy26DMBBF9/kKbyo1C1tjY8wjHxNZxCFOzSNgtTRV/r1AEyUGs6jYzhzu8XgsyJ5Se83ONaCfDUIY7+kB8uTyASmirO52TpGmiAGJWBRMOyxFGEioCrccPMu3DXm4mMfFfS5OaEzjVS7ucQmfKyIiELNT/MslPK7Z6XtXQAkkkKxyxXMXA4+rrJpCGp+IcbrgenRedNKj862MU8JDHq4aLZu7At/Ketfy82AQBwvT3TsvRjU3ct/iYkb6vdFV0+Wj61iVFh9loc13ij5l8z581gndhsfDdogxylrV4LaWmS7zMenutaqzWBqdlylqrWysE38a47PKVM00WGwdUi+TkUueR9LoUuGT0vnJ9u8a4M1hjIdhE6bwMGLClM/r+bozIYCDVHMkmSD1E2n1VfWbG+7uBbhMgb+f3e0Xb7EzRBAFAAA=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var based = '_1d0g9qk4 _11tzcjp4';
export var big = '_1d0g9qk4 _11tzcjp8';
export var extra = '_1d0g9qk4 _11tzcjpc';
export var extrasmall = '_1d0g9qk4 _11tzcjp0';
export var large = '_1d0g9qk4 _11tzcjpa';
export var medium = '_1d0g9qk4 _11tzcjp6';
export var small = '_1d0g9qk4 _11tzcjp2';
export var typographyRecipe = _7a468({defaultClassName:'_11tzcjpg',variantClassNames:{color:{text:'_11tzcjph',mainText:'_11tzcjpi'},lineHeight:{'1':'_11tzcjpj',none:'_11tzcjpk','1.2':'_11tzcjpl','1.6':'_11tzcjpm'},weight:{medium:'_11tzcjpn',black:'_11tzcjpo'},sizes:{xxxsmall:'_11tzcjpp',extrasmall:'_11tzcjpq',small:'_1d0g9qk4 _11tzcjp2',based:'_1d0g9qk4 _11tzcjp4',medium:'_1d0g9qk4 _11tzcjp6',big:'_1d0g9qk4 _11tzcjp8',large:'_1d0g9qk4 _11tzcjpa',extra:'_1d0g9qk4 _11tzcjpc',ultra:'_1d0g9qk4 _11tzcjpe'}},defaultVariants:{color:'mainText',lineHeight:'none'},compoundVariants:[]});
export var ultra = '_1d0g9qk4 _11tzcjpe';