import React, { FC, ReactElement } from "react";
import { Container, Card, Loader, ToggleGroup } from "../../components";
import {
  useAppContext,
  useAppContextLoading,
  useAppDispatch,
  useProjectTableView,
} from "../../context";
import { content } from "./Content.css";
import { isNull } from "lodash-es";
import { Table, PeriodTable, LineChartForWeek } from "../";
export interface ContentProps {
  children?: ReactElement | ReactElement[];
}

const MainSiteTodayYesterdayMetrics = () => {
  const { metrics: metricsMap, projects, sites, date } = useAppContext();
  const metrics = Object.values(metricsMap);
  if (metrics.length === 0) {
    return null;
  }
  if (sites === "main") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {metrics.map((metric) => {
          const data = projects.map((project) => {
            const total = project?.total
              ?.filter(
                (projectMetric) => projectMetric.metric.id === metric.id
              )[0]
              ?.value?.toFixed(2);

            return {
              id: project.id,
              color: project.color ?? "",
              label: project.name,
              site: project.site,
              count: parseFloat(total),
            };
          });

          return (
            <Card
              key={metric.id}
              label={metric.name}
              data={data}
              graph={metric.graph_type}
            />
          );
        })}
      </div>
    );
  }
  return null;
};

const AllTableTodayYesterday = () => {
  const { sites, group } = useAppContext();
  const { columns, data } = useProjectTableView();
  if (sites === "all" || group) {
    return (
      <Table columns={columns} data={data} showSort={true} isPeriod={false} />
    );
  }
  return null;
};

const Content: FC<ContentProps> = ({ children }) => {
  const { setQueries } = useAppDispatch();
  const { group, groups, sites } = useAppContext();
  const { isLoadingProjects } = useAppContextLoading();

  return (
    <Container>
      <div className={[content].join(" ")}>
        {isLoadingProjects && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flexGrow: "1",
            }}
          >
            <Loader />
          </div>
        )}
        {!isLoadingProjects && (
          <>
            {sites === "all" && (
              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  height: "min-content",
                  flexWrap: "wrap",
                }}
              >
                <ToggleGroup
                  key={sites}
                  value={isNull(group) ? sites : null}
                  onValueChange={setQueries}
                  disabled={isLoadingProjects}
                  isOnlyToggler={true}
                  togglers={[
                    {
                      value: "all",
                      label: "Все",
                    },
                  ]}
                />
                <ToggleGroup
                  key={group}
                  onValueChange={setQueries}
                  disabled={isLoadingProjects}
                  value={group}
                  togglers={[
                    ...groups.map((item) => ({
                      value: `${item.id}`,
                      label: item.name,
                    })),
                  ]}
                />
              </div>
            )}
            <LineChartForWeek />
            <MainSiteTodayYesterdayMetrics />
            <AllTableTodayYesterday />
            <PeriodTable />
          </>
        )}
      </div>
    </Container>
  );
};

export { Content };
