import React, { FC, ReactElement } from "react";
import classes from "./Layout.css";

export interface LayoutProps {
  header?: ReactElement | ReactElement[];
  content?: ReactElement | ReactElement[];
}

const Layout: FC<LayoutProps> = ({ header, content }) => {
  return (
    <div className={[classes.layout].join(" ")}>
      <div className={[classes.header].join(" ")}>{header}</div>
      <hr className={classes.hr} />
      <div className={[classes.content].join(" ")}>{content}</div>
    </div>
  );
};

export { Layout };
