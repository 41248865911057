import { FC } from "react";
import { Doughnut } from "react-chartjs-2";
import { useMediaQuery } from "usehooks-ts";

// ChartJS.register(ArcElement, Tooltip, Legend);

//  const data = {
//   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
//   datasets: [
//     {
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [

//       ],
//       borderWidth: 1,
//     },
//   ],
// };

interface DoughnutChartProps {
  data: Array<{
    label: string;
    count: number;
    color: string;
  }>;
}
const DoughnutChart: FC<DoughnutChartProps> = ({ data }) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");

  const createChart = data.reduce<{
    labels: Array<string>;
    dataset: {
      data: Array<number>;
      backgroundColor: Array<string>;
    };
  }>(
    (acc, item) => {
      return {
        ...acc,
        labels: [...acc.labels, item.label],
        dataset: {
          data: [...acc.dataset.data, item.count],
          backgroundColor: [...acc.dataset.backgroundColor, item.color],
          borderWidth: 0,
        },
      };
    },
    {
      labels: [],
      dataset: {
        data: [],
        backgroundColor: [],
      },
    }
  );
  return (
    <Doughnut
      width={isDesktopView ? 256 : 200}
      height={isDesktopView ? 256 : 200}
      options={{
        cutout: 70,
        plugins: {
          tooltip: {
            caretSize: 0,
            padding: 10,
            boxPadding: 4,
            backgroundColor: "#1E1E20",
            multiKeyBackground: "rgba(0, 0, 0, 0, 0)",
            titleFont: {
              family: "Inter",
              size: 14,
              style: "normal",
            },
            bodyFont: {
              family: "Inter",
              size: 14,
              style: "normal",
            },
            callbacks: {
              labelColor: function () {
                return {
                  borderColor: "rgba(0,0,0,0)",
                  backgroundColor: "#78B6E7",
                  borderWidth: 0,
                  borderRadius: 10,
                };
              },
            },
          },
          legend: {
            display: false,
          },
        },
      }}
      data={{
        labels: createChart.labels,
        datasets: [createChart.dataset],
      }}
    />
  );
};

export { DoughnutChart };
