import { FC } from "react";
import { Provider } from "react-redux";

import { Layout } from "./components";
import { Header, Content } from "./feature";
import { app } from "./styles/App.css";
import { mainTheme } from "./styles/theme.css";
import logo from "./bg_logo.svg";
import "./styles/index.css";
import { AppContextStateMenager } from "./context";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import store from "./context/store";

ChartJS.register(
  LineElement,
  PointElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const App: FC<{}> = () => {
  return (
    <Provider store={store}>
      <AppContextStateMenager>
        <div
          style={{
            backgroundPositionX: "112%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${logo})`,
          }}
          className={[app, mainTheme].join(" ")}
        >
          <Layout header={<Header />} content={<Content />} />
        </div>
      </AppContextStateMenager>
    </Provider>
  );
};

export default App;
