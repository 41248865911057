import React, { FC, ReactElement } from "react";
import { ToggleGroup, Container } from "../../components";
import {
  useAppContext,
  useAppDispatch,
  useAppContextLoading,
} from "../../context";
import { typographyRecipe } from "../../styles/typhography.css";
import { header, togglers, logo } from "./header.css";
import { TotalBox } from "../Total";
export interface HeaderProps {
  children?: ReactElement | ReactElement[];
}

const Header: FC<HeaderProps> = ({ children }) => {
  const { sites, data, date, groups, group } = useAppContext();
  const { setQueries } = useAppDispatch();
  const { isLoadingProjects } = useAppContextLoading();

  const isLoading = isLoadingProjects;
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          height: "min-content",
          gap: "64px",
        }}
      >
        <div className={header}>
          <div className={togglers}>
            <ToggleGroup
              key={sites}
              value={sites}
              onValueChange={setQueries}
              disabled={isLoading}
              togglers={[
                {
                  value: "main",
                  label: "Основные сайты",
                },
                {
                  value: "all",
                  label: "РегСайты",
                },
              ]}
            />
            <ToggleGroup
              value={date}
              onValueChange={setQueries}
              disabled={isLoading}
              togglers={[
                {
                  value: "today",
                  label: "Сегодня",
                },
                {
                  value: "yesterday",
                  label: "Вчера",
                },
                {
                  value: "week",
                  label: "Неделя",
                },
                {
                  value: "month",
                  label: "Месяц",
                },
                {
                  value: "year",
                  label: "Год",
                },
              ]}
            />
          </div>
          <div
            className={[
              logo,
              typographyRecipe({ sizes: "large", weight: "black" }),
            ].join(" ")}
          >
            Медиа Подмосковья
          </div>
        </div>
        <TotalBox />
      </div>
    </Container>
  );
};

export { Header };
