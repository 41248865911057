import React, { FC } from "react";
import {
  useMetricsTotal,
  useAppContextLoading,
  useAppContext,
} from "../../context";
import { typographyRecipe } from "../../styles/typhography.css";
import { niceCount } from "../../utils";
import { total, totalBox } from "./Total.css";

export interface TotalProps {
  className?: string;
  label: string;
  count: number;
}

const Total: FC<TotalProps> = ({ className = "", label, count }) => {
  return (
    <div className={[className, total].join(" ")}>
      <span className={typographyRecipe({ sizes: "big", weight: "black" })}>
        {label}
      </span>
      <span className={typographyRecipe({ sizes: "ultra", weight: "black" })}>
        {niceCount(count)}
      </span>
    </div>
  );
};

export interface TotalBoxProps {}

function isInt(n: number) {
  return n % 1 === 0;
}

const dataToText = {
  all: "Все",
  deep: "Глубина",
  views: "Просмотры",
  visits: "Визиты",
};

const dateToText = {
  today: "За сегодня",
  yesterday: "За вчера",
  week: "За неделю",
  month: "За месяц",
  year: "За год",
};

const TotalDateData: FC<{}> = () => {
  const { data, date, sites } = useAppContext();
  const { isLoadingProjects } = useAppContextLoading();
  const { total } = useMetricsTotal();

  if (isLoadingProjects) {
    return null;
  }

  if (date === "year" && sites === "main") {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: "1",
        justifyContent: "flex-start",
        columnGap: "clamp(64px , 15vw , 326px)",
        rowGap: "64px",
      }}
    >
      {total.map((item) => {
        if (!item.is_in_header) {
          return null;
        }
        return (
          <Total
            key={item.id}
            label={item.name}
            count={
              isInt(item.total) ? item.total : parseFloat(item.total.toFixed(2))
            }
          />
        );
      })}
    </div>
  );
};

const TotalBox: FC<TotalBoxProps> = () => {
  const { date } = useAppContext();

  return (
    <div className={totalBox}>
      <span
        style={{
          width: "221px",
        }}
        className={typographyRecipe({
          sizes: "extra",
          weight: "black",
        })}
      >
        <>
          Статистика <br />
        </>
        {dateToText[date]}
      </span>

      <TotalDateData />
    </div>
  );
};

export { TotalBox };
