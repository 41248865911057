import { createModel } from "@rematch/core";
import { isNull } from "lodash-es";
import { RootModel } from ".";
import * as API from "../../api";
import {
  DataToggle,
  DateToggle,
  Metric,
  Project,
  SitesToggle,
} from "../../type";
import { niceCount } from "../../utils";

const dataQueryMap = {
  visits: "Визиты",
  views: "Просмотры",
  deep: "Глубина",
  dzenVisits: "Дзен Новости: визиты",
};

const reDataQueryMap = {
  "ym:s:visits": "visits",
  "ym:s:pageviews": "views",
  "ym:s:pageDepth": "deep",
};

interface MegaTotal extends Metric {
  total: number;
}

interface AppContextProps {
  sites: SitesToggle | null;
  group: string | null;
  date: DateToggle;
  data: DataToggle;
  groups: Array<{ id: number; name: string }>;
  periodDates: Array<string>;
  projects: Array<Project>;
  metrics: {
    [key: string | number]: Metric;
  };
}

function isSitesToggle(string: string): string is SitesToggle {
  const array: Array<string> = ["all", "main"];
  return array.includes(string);
}

function isDateToggle(string: string): string is DateToggle {
  const array: Array<string> = ["month", "today", "yesterday", "week", "year"];
  return array.includes(string);
}

function isDataToggle(string: string): string is DataToggle {
  const array: Array<string> = ["deep", "views", "visits", "dzenVisits"];
  return array.includes(string);
}

interface MegaTotal extends Metric {
  total: number;
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const rootState = createModel<RootModel>()({
  state: {
    sites: "main",
    group: null,
    data: "visits",
    date: "today",
    groups: [],
    periodDates: [],
    projects: [],
    metrics: {},
  } as AppContextProps,
  reducers: {
    setSite: (state, payload: SitesToggle) => ({
      ...state,
      sites: payload,
      group: null,
    }),
    setDate: (state, payload: DateToggle) => ({
      ...state,
      date: payload,
    }),
    setData: (state, payload: DataToggle) => ({
      ...state,
      data: payload,
    }),
    setGroup: (state, payload: string) => ({
      ...state,
      group: payload,
      sites: state.sites === "all" ? "all" : null,
    }),
    setMetrics: (state, payload: Array<Metric>) => ({
      ...state,
      metrics: payload.reduce((acc, item) => {
        return { ...acc, [item.id]: item };
      }, {}),
    }),
    setProjects: (state, payload: Array<Project>) => ({
      ...state,
      projects: payload,
    }),
    setPeriod: (state, payload: Array<string>) => ({
      ...state,
      periodDates: payload,
    }),
    setGroups: (state, payload: Array<{ id: number; name: string }>) => ({
      ...state,
      groups: payload,
    }),
    update: (
      state,
      {
        periodDates,
        projects,
        metrics,
      }: {
        periodDates: Array<string>;
        projects: Array<Project>;
        metrics: Array<Metric>;
      }
    ) => ({
      ...state,
      periodDates,
      projects,
      metrics: metrics.reduce((acc, item) => {
        return { ...acc, [item.id]: item };
      }, {}),
    }),
  },
  selectors: (slice) => ({
    metricsArray() {
      return slice(({ metrics }) => Object.values(metrics));
    },
    total() {
      return slice(({ metrics, projects }) => {
        const totalMetrics = Object.values(metrics).reduce<Array<MegaTotal>>(
          (acc, metric) => {
            let value = 0;
            projects.forEach((project) => {
              project.total.forEach((projectMetric) => {
                if (metric.id === projectMetric.metric.id) {
                  value = value + projectMetric.value;
                }
              });
            });
            return [...acc, { ...metric, total: value }];
          },
          []
        );
        return totalMetrics;
      });
    },
    tableColumns() {
      return slice(({ metrics }) => {
        return {
          columns: [
            {
              Header: "Проекты",
              accessor: "name",
            },
            ...Object.values(metrics).map((item) => ({
              Header: item.name,
              accessor: item.name,
            })),
          ],
        };
      });
    },
    table() {
      return slice(({ metrics, projects }) => {
        return {
          data: projects.map((project) => {
            const columns = project.metrics.reduce((acc, metric) => {
              return {
                ...acc,
                [metric.metric.name]: metric.value,
              };
            }, {});

            return {
              name: project.name,
              site: project.site,
              ...columns,
            };
          }),
        };
      });
    },
    lineChartView() {
      return slice(({ projects, periodDates, data, sites }) => {
        return projects.reduce<
          Array<{
            [key: string]: any;
          }>
        >((acc, project) => {
          console.log({ project });
          return [
            ...acc,
            {
              label: project.name,
              borderColor: project.color,
              data: periodDates.map((date) => {
                return project.metrics.reduce<number>((acc, metric) => {
                  if (
                    metric.metric.name === dataQueryMap[data] &&
                    date === metric.date
                  ) {
                    return metric.value;
                  }
                  return acc;
                }, NaN);
              }),
            },
          ];
        }, []);
      });
    },
    byWeek() {
      return slice(({ projects, periodDates }) => {
        //
      });
    },
    byDayProject() {
      return slice(({ projects, periodDates }) => {
        return periodDates.reduce<{
          [key: string]: {
            [key: string]: any;
          };
        }>((acc, date) => {
          return {
            ...acc,
            [date]: projects.map((project) => {
              const currentMetrics = project.metrics
                .filter((item) => item.date === date)
                .reduce((acc, metric) => {
                  return {
                    ...acc,
                    [metric.metric.name]: niceCount(metric.value),
                  };
                }, {});

              return {
                name: project.name,
                site: project.site,
                ...currentMetrics,
              };
            }),
          };
        }, {});
      });
    },
  }),
  effects: (dispatch) => ({
    setQueries: (payload: string, state) => {
      if (isSitesToggle(payload)) {
        dispatch.rootState.setSite(payload);
      }
      if (isDateToggle(payload)) {
        dispatch.rootState.setDate(payload);
      }
      if (isDataToggle(payload)) {
        dispatch.rootState.setData(payload);
      }
      if (!isNaN(parseInt(payload))) {
        dispatch.rootState.setGroup(payload);
      }
    },
    getProjects: async (_, state) => {
      try {
        const { metrics, period_dates, projects } = await API.getProjects({
          is_important: state.rootState.sites === "main",
          period: state.rootState.date,
          group: isNull(state.rootState.group)
            ? undefined
            : state.rootState.group,
        });
        dispatch.rootState.update({
          projects,
          metrics,
          periodDates: period_dates,
        });

        await delay(3000);

        return true;
      } catch {
        dispatch.rootState.setProjects([]);
        return true;
      }
    },
    getGroups: async (_, state) => {
      try {
        const groups = await API.getGroups();
        dispatch.rootState.setGroups(groups);
      } catch (e) {
        dispatch.rootState.setGroups([]);
      }
    },
  }),
});
