import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import selectPlugin from "@rematch/select";

import { models, RootModel } from "./models";

type FullModel = ExtraModelsFromLoading<RootModel>;

const store = init<RootModel, FullModel>({
  models,
  plugins: [loadingPlugin(), selectPlugin()],
  redux: {
    devtoolOptions: {
      actionSanitizer: (action) => action,
    },
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

export default store;
