import { da } from "date-fns/locale";
import { omit } from "lodash-es";
import React, { FC } from "react";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "usehooks-ts";
import { Checkbox } from "../Checkbox";
import { barChart } from "./BarChart.css";

const labels = ["14.09.22"];
const data = {
  labels: labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [10],
      backgroundColor: "#78B6E7",
    },
    {
      label: "Dataset 2",
      data: [20],
      backgroundColor: "#FE5A3E",
    },
    {
      label: "Dataset 2",
      data: [30],
      backgroundColor: "#FFD964",
    },
    {
      label: "Dataset 2",
      data: [40],
      backgroundColor: "#97CC64",
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
    ticks: {
      font: "Inter",
    },
  },
};

export interface ChartProps {
  data: Array<{
    label: string;
    count: number;
    color: string;
  }>;
}

const BarChart: FC<ChartProps> = ({ data }) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");

  const dataset = data.map(({ count, color, label }) => ({
    data: [count],
    label,
    backgroundColor: color,
  }));
  return (
    <Bar
      data={{
        labels: [""],
        datasets: dataset,
      }}
      width={isDesktopView ? 490 : 260}
      height={isDesktopView ? 256 : 200}
      options={{
        ...options,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            caretSize: 0,
            padding: 10,
            boxPadding: 4,
            backgroundColor: "#1E1E20",
            multiKeyBackground: "rgba(0, 0, 0, 0, 0)",
            titleFont: {
              family: "Inter",
              size: 14,
              style: "normal",
            },
            bodyFont: {
              family: "Inter",
              size: 14,
              style: "normal",
            },
            callbacks: {
              labelColor: function () {
                return {
                  borderColor: "rgba(0,0,0,0)",
                  backgroundColor: "#78B6E7",
                  borderWidth: 0,
                  borderRadius: 10,
                };
              },
            },
          },
        },

        scales: {
          x: {
            grid: {
              color: "#9A9A9A",
              borderColor: "#9A9A9A",
              drawTicks: false,
            },
            ticks: {
              font: {
                family: "Inter",
                weight: "bold",
              },
              color: "#9A9A9A",
              textStrokeColor: "red",
            },
          },
          y: {
            grid: {
              color: "#9A9A9A",
              borderColor: "#9A9A9A",
              drawTicks: false,
            },
            ticks: {
              font: {
                family: "Inter",
                weight: "bold",
              },
              color: "#9A9A9A",
              textStrokeColor: "red",
            },
          },
        },
      }}
    />
  );
};

export { BarChart };
