import React, { FC } from "react";
import { Line } from "react-chartjs-2";
import { useMediaQuery } from "usehooks-ts";
import { lineChart } from "./LineChart.css";

const options = {
  plugins: {
    legend: {
      display: false,
    },
    ticks: {
      font: "Inter",
    },
  },
};

export interface LineChartProps {
  labels?: Array<string>;
  datasets?: Array<{
    label: string;
    data: Array<number>;
    color: string;
  }>;
}

const LineChart: FC<LineChartProps> = ({ labels = [], datasets = [] }) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");
  const isMobileView = !isDesktopView;
  return (
    <div className={[lineChart({ isMobile: isMobileView })].join(" ")}>
      <Line
        data={{
          labels,
          datasets,
        }}
        width={isMobileView ? 210 : undefined}
        height={421}
        options={{
          ...options,
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              padding: 10,
              boxPadding: 4,
              backgroundColor: "#1E1E20",
              multiKeyBackground: "rgba(0, 0, 0, 0, 0)",
              titleFont: {
                family: "Inter",
                size: 14,
                style: "normal",
              },
              bodyFont: {
                family: "Inter",
                size: 14,
                style: "normal",
              },
              callbacks: {
                labelColor: function () {
                  return {
                    borderColor: "rgba(0,0,0,0)",
                    backgroundColor: "#78B6E7",
                    borderWidth: 0,
                    borderRadius: 10,
                  };
                },
              },
            },
          },

          scales: {
            x: {
              display: isDesktopView,
              grid: {
                color: "#9A9A9A",
                borderColor: "#9A9A9A",
                drawTicks: false,
              },
              ticks: {
                font: {
                  family: "Inter",
                  weight: "bold",
                },
                color: "#9A9A9A",
                textStrokeColor: "red",
              },
            },
            y: {
              display: isDesktopView,
              grid: {
                color: "#9A9A9A",
                borderColor: "#9A9A9A",
                drawTicks: false,
              },
              ticks: {
                font: {
                  family: "Inter",
                  weight: "bold",
                },
                color: "#9A9A9A",
                textStrokeColor: "red",
              },
            },
          },
        }}
      />
    </div>
  );
};

export { LineChart };
