import { isNumber } from "lodash-es";
import { FC, useMemo } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useSortBy, useTable } from "react-table";
import { typographyRecipe } from "../../styles/typhography.css";
import * as baseClasses from "./Table.base.css";
import * as mobileClasses from "./Table.mobile.css";
import { niceCount } from "../../utils";
import { useMediaQuery } from "usehooks-ts";

function MyCell({ value, ...props }: { value: number }) {
  if (isNumber(value)) {
    return <span>{niceCount(value)}</span>;
  }

  return <span>{value}</span>;
}

function MyName({ value, row, ...props }: { value: number; row: any }) {
  return (
    <a
      style={{
        color: "unset",
        textDecoration: "none",
      }}
      href={row.original.site}
      target={"_blank"}
      rel="noreferrer"
    >
      <span>{value}</span>
    </a>
  );
}

export interface TableProps {
  columns: any;
  data: any;
  showHead?: boolean;
  showLastBorder?: boolean;
  showSort?: boolean;
  isPeriod?: boolean;
}

export const Table: FC<TableProps> = ({
  columns,
  data,
  showHead = true,
  showLastBorder = true,
  showSort = false,
  isPeriod = false,
}) => {
  const isDesktopView = useMediaQuery("(min-width:768px)");
  const isMobileView = !isDesktopView;
  const columnsTable = useMemo(
    //@ts-ignore
    () =>
      columns.map((item: any) =>
        item.accessor === "name"
          ? { ...item, Cell: MyName }
          : { ...item, Cell: MyCell }
      ),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columnsTable,
        data,
        initialState: {
          //@ts-ignore
          sortBy: [
            {
              id: "ym:s:visits",
              desc: true,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <table
      {...getTableProps()}
      className={[
        baseClasses.tableRecipe({ showHead: !showHead }),
        isMobileView ? mobileClasses.mobileTable({ isPeriod: isPeriod }) : "",
      ].join(" ")}
    >
      {showHead && (
        <thead
          className={[
            isMobileView
              ? mobileClasses.mobileTheadRecipe({ isPeriod: isPeriod })
              : "",
          ].join(" ")}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className={[
                isMobileView ? mobileClasses.mobileTrRecipe({ isPeriod }) : "",
              ].join(" ")}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(
                      //@ts-ignore
                      showSort ? column.getSortByToggleProps() : undefined
                    )}
                    className={[
                      isMobileView
                        ? mobileClasses.mobileThRecipe({ isPeriod })
                        : "",
                      isMobileView
                        ? typographyRecipe({
                            color: "mainText",
                            sizes: "extrasmall",
                            weight: "black",
                          })
                        : typographyRecipe({
                            color: "mainText",
                            sizes: "big",
                            weight: "black",
                          }),
                      showSort ? baseClasses.th : "",
                    ].join(" ")}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      {column.render("Header")}
                      {showSort && (
                        <span>
                          {/* @ts-ignore */}
                          {column.isSorted ? (
                            //@ts-ignore
                            column.isSortedDesc ? (
                              <FiArrowUp />
                            ) : (
                              <FiArrowDown />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          const rowsLength = rows.length;

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={[
                      isMobileView
                        ? typographyRecipe({
                            color: "text",
                            sizes: "xxxsmall",
                            weight: "medium",
                          })
                        : typographyRecipe({
                            color: "text",
                            sizes: "big",
                            weight: "medium",
                          }),
                      baseClasses.td,
                    ].join(" ")}
                    style={{
                      ...(showLastBorder
                        ? { borderBottom: "solid 1px #3A3B3F" }
                        : rowIndex + 1 !== rowsLength
                        ? { borderBottom: "solid 1px #3A3B3F" }
                        : {}),
                      ...(index !== 0 ? { width: "141px" } : {}),
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
