import { chunk } from "lodash-es";

function niceCount(number: number): string {
  function isInt(n: number) {
    return n % 1 === 0;
  }
  function сrushCount(str: string) {
    return chunk(`${str}`.split("").reverse(), 3)
      .map((item) => item.reverse().join(""))
      .reverse()
      .join(" ");
  }
  if (isInt(number)) {
    return сrushCount(`${number}`);
  } else {
    const [value, junk] = `${number}`.split(".");
    return `${сrushCount(value)}.${junk}`;
  }
}

export { niceCount };
